import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import { GlobeIconStyled, RegionalMenuContainer } from './styles'

const RegionalMenuPopover = dynamic(() => import('./components/RegionalMenuPopover'))

type Props = {
  placement?: TooltipPlacement
}

export const RegionalMenu: FC<Props> = ({ placement }) => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)

  return (
    <RegionalMenuContainer>
      <GlobeIconStyled />
      {isPageInteraction && <RegionalMenuPopover placement={placement} />}
    </RegionalMenuContainer>
  )
}
